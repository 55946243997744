import request from '@/utils/request'


// 查询抽奖活动中奖记录列表
export function listLuckActivityRecord(query) {
  return request({
    url: '/luckActivityRecord/luckActivityRecord/list',
    method: 'get',
    params: query
  })
}

// 查询抽奖活动中奖记录分页
export function pageLuckActivityRecord(query) {
  return request({
    url: '/luckActivityRecord/luckActivityRecord/page',
    method: 'get',
    params: query
  })
}

// 查询抽奖活动中奖记录详细
export function getLuckActivityRecord(data) {
  return request({
    url: '/luckActivityRecord/luckActivityRecord/detail',
    method: 'get',
    params: data
  })
}

// 新增抽奖活动中奖记录
export function addLuckActivityRecord(data) {
  return request({
    url: '/luckActivityRecord/luckActivityRecord/add',
    method: 'post',
    data: data
  })
}

// 修改抽奖活动中奖记录
export function updateLuckActivityRecord(data) {
  return request({
    url: '/luckActivityRecord/luckActivityRecord/edit',
    method: 'post',
    data: data
  })
}

// 删除抽奖活动中奖记录
export function delLuckActivityRecord(data) {
  return request({
    url: '/luckActivityRecord/luckActivityRecord/delete',
    method: 'post',
    data: data
  })
}

//发货
export function luckDelivery (data) {
  return request({
    url: '/luckActivityRecord/luckActivityRecord/luckDelivery',
    method: 'post',
    data: data
  })
}

//物流详情
export function getLuckLogistics (data) {
  return request({
    url: '/luckActivityRecord/luckActivityRecord/getLuckLogistics',
    method: 'post',
    data: data
  })
}
