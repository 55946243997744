<template>
  <a-drawer width="70%" :label-col="4" :wrapper-col="14" :visible="open"  @cancel="onClose" @close="onClose">
    <a-divider orientation="left">
      <b>{{$t('抽奖活动中奖记录.抽奖详情')}}</b>
    </a-divider>
    <div class="order-info">
      <table>
        <tr>
          <td style="vertical-align: top;background-color:#fbfbfb;">
            <a-card  :bordered="false" :title="$t('抽奖活动中奖记录.抽奖信息')"
                    :headStyle="{'background-color':'#f3f3f3','height': '29px','border-bottom': '1px solid #DDD','margin-bottom':'0'}"
                    :bodyStyle="{'background-color':'#fbfbfb'}">
              <table class="info-table">
                <tr>
                  <td class="first">{{$t('抽奖活动中奖记录.抽奖人昵称')}}：</td>
                  <td class="data">
                    {{ this.record.nickName}}
                  </td>
                  <td class="first">{{$t('抽奖活动中奖记录.抽奖人手机号')}}：</td>
                  <td class="data">
                    {{this.record.mobile}}
                  </td>
                  <td class="first"></td>
                  <td class="data">

                  </td>
                </tr>

                <tr>
                  <td class="first">{{$t('抽奖活动中奖记录.奖励类型')}}：</td>
                  <td class="data">
                     <span v-if="record.type == '1'">
                       {{$t('奖励类型.谢谢惠顾')}}
                     </span>
                    <span v-if="record.type == '2'">
                     {{$t('奖励类型.平台币')}}
                    </span>
                    <span v-if="record.type == '3'">
                     {{$t('奖励类型.商品')}}
                    </span>
                    <span v-if="record.type == '4'">
                     {{$t('奖励类型.优惠券')}}
                    </span>
                  </td>
                </tr>





                <tr v-if="this.record.type == '2'">
                  <td class="first">{{$t('抽奖活动中奖记录.奖励金额')}}：</td>
                  <td class="data">
                    {{ this.record.gold}}
                  </td>
                </tr>

                <tr v-if="this.record.type == '3'">
                  <td class="first">{{$t('抽奖活动中奖记录.商品名称')}}：</td>
                  <td class="data">
                    {{ this.record.goodsName}}
                  </td>
                  <td class="first">{{$t('抽奖活动中奖记录.商品图片')}}：</td>
                  <td class="data">
                    <img :src="this.record.goodsPictire" width="100" height="100">
                  </td>
                </tr>

                <tr v-if="this.record.type == '3'">
                  <td class="first">{{$t('抽奖活动中奖记录.邮寄状态')}}：</td>
                  <td class="data">
                    <span v-if="record.mailStatus == '1'">
                       {{$t('邮寄状态.待申请邮寄')}}
                     </span>
                    <span v-if="record.mailStatus == '2'">
                       {{$t('邮寄状态.待邮寄')}}
                     </span>
                    <span v-if="record.mailStatus == '3'">
                       {{$t('邮寄状态.已邮寄')}}
                     </span>
                  </td>
                </tr>

                <tr v-if="this.record.type == '3'">
                  <td class="first">{{$t('抽奖活动中奖记录.姓名')}}：</td>
                  <td class="data">
                    {{ this.record.realName}}
                  </td>
                  <td class="first">{{$t('抽奖活动中奖记录.手机号')}}：</td>
                  <td class="data">
                    {{ this.record.mobile}}
                  </td>
                  <td class="first">{{$t('抽奖活动中奖记录.详细地址')}}：</td>
                  <td class="data">
                    {{ this.record.detailedAddress}}
                  </td>
                </tr>

                <tr v-if="this.record.type == '3'">
                  <td class="first">{{$t('抽奖活动中奖记录.物流公司名称')}}：</td>
                  <td class="data">
                    {{ this.record.logisticsName}}
                  </td>
                  <td class="first">{{$t('抽奖活动中奖记录.寄件人手机号')}}：</td>
                  <td class="data">
                    {{ this.record.deliveryMobile}}
                  </td>
                  <td class="first">{{$t('抽奖活动中奖记录.物流编号')}}：</td>
                  <td class="data">
                    {{ this.record.logisticsNo}}
                  </td>
                </tr>

                <tr v-if="this.record.type == '4'">
                  <td class="first">{{$t('抽奖活动中奖记录.优惠券名称')}}：</td>
                  <td class="data">
                    {{ this.record.couponName}}
                  </td>
                </tr>

              </table>
            </a-card>
          </td>
        </tr>


      </table>


    </div>
    <div class="bottom-control">
      <a-space>

        <a-button type="dashed" @click="cancel">
          {{$t('通用.按钮.关闭')}}
        </a-button>
      </a-space>
    </div>
  </a-drawer>
</template>

<script>

import { getLuckActivityRecord, addLuckActivityRecord, updateLuckActivityRecord,luckDelivery } from '@/api/luckActivityRecord/luckActivityRecord'
import {mapGetters} from 'vuex'
import CustomDictTag from "@/components/DictCustomTag";

export default {
  name: 'LuckDetailForm',
  props: {
  },
  components: {
    CustomDictTag,
  },
  data () {
    return {
      record: {
        id:null,
        orderAddressDetail: {},
        orderActivityDetail: {},
      } ,
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      submitLoading: false,
      // 表单参数
      form: {
        id: null,
        outTradeNo: null,
        remark: null,

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      //预览封面
      previewImageVisible: false,
      previewUrl: false,
      rules: {
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
    ...mapGetters(['customDict'])
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
      this.reset()
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        outTradeNo: null,
        remark: null,
      }
    },
    /** 修改按钮操作 */
    handleDetail(id) {
      this.reset()
      getLuckActivityRecord({"id":id}).then(response => {debugger;
        this.record = response.data
        this.open = true
      })
    },
  }
}
</script>

<style lang="less" scoped>
.title {
  color: rgba(0, 0, 0, .85);
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 16px;
}

.message-text {
  font-family: MicrosoftYaHei;
  font-size: 1rem;
  font-weight: normal;
  font-stretch: normal;
  line-height: 3rem;
  letter-spacing: 0rem;
  color: #333333;
  width: 50%;
}

.fontblack {
  color: #999999
}

.img2 {
  width: .81rem;
  height: .8rem;
  float: right;
}


.orderTitle {
  font-size: 1rem;
  color: #333333;
  height: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: normal;
  word-wrap: break-word;
  word-break: break-all;
  height: 2.5rem;
}

.orderDetail {
  font-size: 0.8rem;
  color: #666666;
  text-align: left;
}

.border-ceter {
  width: 92%;
  padding-left: 15px;
  padding-right: 15px;
}

.pay-button {
  width: 88%;
  height: 2.6rem;
  position: relative;
  background-color: red;
  color: white;

  margin-left: 6%;
}

ul li {
  list-style: none;
  font-size: 1rem;
}

ul {
  padding-left: 1.5rem
}

.track-rcol {
}

.track-list {
  position: relative;
}

.track-list li {
  position: relative;
  padding: 0px 0 1.5rem 25px;
  line-height: 1rem;
  border-left: 1px solid #d9d9d9;
  color: #999;
}

.track-list li.first {
  color: red;
  padding-top: 0;
  width: 100%;
  text-align: left;
  border-left: 1px solid #d9d9d9;
}

.track-list li .node-icon {
  position: absolute;
  left: -5.5px;
  border-radius: 0.25rem;
  width: 0.5rem;
  height: 0.5rem;
  top: 4px;
  background-color: #999999;
}

.track-list li.first .node-icon {
  background-position: 0-72px;
  background-color: red;
  width: 1rem;
  z-index: 2;
  height: 1rem;
  position: absolute;
  left: -9px;
  top: 0;
  border-radius: 0.5rem;
}

.track-list li .time {
  margin-right: 20px;
  position: relative;
  top: 4px;
  display: inline-block;
  vertical-align: middle;
  background-color: white;
  color: #999;
  width: 100%;
  text-align: left;
}

.track-list li .txt {
  position: relative;
  display: inline-block;
  vertical-align: top;
  color: #999;
}

.track-list li.first .time {
  text-align: left;
  width: 94%;
  color: red;
}

.track-list li.first .txt {
  color: red;
  text-align: left;
  width: 94%;
}

.track-list li.finall {
  position: relative;
  padding: 0px 0 1.5rem 25px;
  line-height: 18px;
  border-color: white;
  border-left: 1px solid #ffffff;
  color: #999;
}

.track-list li.finall .div-spilander {
  width: 1px;
  position: absolute;
  position: absolute;
  left: -1.5px;
  height: 0.5rem;
  background-color: #d9d9d9;
}

.order-info {
  border: 1px solid #e8e8e8;
  margin-top: 40px;

  .info-table {
    tr {
      height: 50px;
    }
    td {
      display: table-cell;
      vertical-align: top;
      font-size: 16px;
    }

    .first {
      width: 150px;
    }

    .data {
      width: 300px;
    }
  }
}

.detailPanel {
  dd, dt {
    display: inline-block;
    vertical-align: center;
  }

  dt img {
    margin: 0 20px 5px 0px;
  }

  dd {
    h3 {
      font-weight: bold;
    }
  }

  .trade-detail-prompt {
    margin-left: 35px;

    li {
      font-size: 13px !important;

      .dotted-node {
        padding: 0 5px;
      }
    }
  }
}

.order-money {
  .om-c1 {
    font-size: 14px;
    color: #666
  }

  .om-c2 {
    font-size: 16px;
    color: #333;
    font-weight: bold;
  }

  .om-c3 {
    font-size: 16px;
    color: #DE3636;
    font-weight: bold;
  }

  td {
    text-align: right;
  }

  float: right;
  margin: 20px 40px 20px 0;
}
</style>
